import React from "react"
import { Link } from "gatsby"
import Seo from "../components/Seo"

const Hello = () => {
  return (
    <>
      <Seo title="Hello and Welcome" />
      <main className="hello-page">
        <div className="hello-container">
          <h1>Howdy.</h1>
          <p>
            Thanks for your inquiry! We'll be sure to get back to you with any
            answers you need within 24 hours.
          </p>
          <p>
            We do occasionally send out emails with news and updates. To be
            taken off the email list, please click Unsubscribe in the welcome
            email that should have landed in your inbox.
          </p>
          <Link to="/" className="btn">
            Back home
          </Link>
        </div>
      </main>
    </>
  )
}

export default Hello
